<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="6" md="3" cols="6">
            <statistic-card-vertical
              color="success"
              icon="BoxIcon"
              :statistic="`${totalLogsNb} Blocks`"
              :statistic-title="`# 0 — ${totalLogsNb-1}`"
            />
          </b-col>
          <!--/ Bar Chart - Orders -->
          <b-col lg="6" md="3" cols="6">
            <statistic-card-vertical
              color="success"
              icon="Share2Icon"
              :statistic="peersConnected"
              statistic-title="Pairs actifs"
            />
          </b-col>
          <b-col lg="12" md="6">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>État du nœud</b-card-title>
              </b-card-header>

              <b-card-body>
                <div
                  v-for="transaction in nodeState"
                  :key="transaction.title"
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        :variant="transaction.avatarVariant"
                      >
                        <feather-icon size="18" :icon="transaction.avatar" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        {{ transaction.title }}
                      </h6>
                      <!-- <small>{{ transaction.types }}</small> -->
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-right">
                    {{ transaction.value }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Logs enregistrés</b-card-title>
            <b-card-sub-title>30 derniers jours</b-card-sub-title>
            <!-- datepicker -->
            <!-- <div class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="16" />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range' }"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
              />
            </div> -->
            <!-- datepicker -->
          </b-card-header>

          <b-card-body>
            <vue-apex-charts
              ref="logsChart"
              type="area"
              height="320"
              :options="apexChartData.chartOptions"
              :series="apexChartData.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!--/ Company Table Card -->
      <b-col lg="12">
        <b-card>
          <b-card-body>
            <b-card-title>Derniers logs</b-card-title>
            <b-row align-h="between">
              <b-col md="4" sm="8">
                <b-form-checkbox
                  v-model="realtimeLogsUpdate"
                  :checked="realtimeLogsUpdate"
                  name="check-button"
                  switch
                  inline
                >
                  Mise à jour temps réel
                </b-form-checkbox>
              </b-col>
              <b-col md="3" class="align-right">
                <b-form @submit="logSearch">
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput"
                        v-model="logSearchInput"
                        type="search"
                        placeholder="Rechercher un # Block"
                      />
                      <b-input-group-append>
                        <b-button type="submit">Rechercher</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>

            <b-table :fields="fields" :items="logs" responsive class="mb-0">
              <!-- A custom formatted column -->
              <template #cell(_signature)="data">
                <span class="text-nowrap">{{
                  data.value.substring(0, 28) + "..."
                }}</span>
              </template>

              <template #cell(status)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>

              <template #cell(_owner_or_delegate)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>

              <!-- A virtual composite column -->
              <!-- <template #cell(horodatage)="data">
                {{ new Date(data.value).toLocaleDateString() }}
                {{ new Date(data.value).toLocaleTimeString() }}
              </template> -->

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>

              <template #cell(id)="data">
                <a :href="`/log/${data.item.id}`">
                  <feather-icon icon="LinkIcon" size="24" />
                </a>
                {{ data.value }}
              </template>

              <template #cell(details)="row">
                <b-button @click="row.toggleDetails" variant="flat-secondary">
                  {{ row._showDetails ? "Détails" : "Détails" }}
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col
                      v-for="(key, idx) in Object.keys(row.item).filter(x => !hideFields.includes(x))"
                      :key="idx"
                      md="4"
                      class="mb-1"
                    >
                      <strong>{{ key }} : </strong>{{ row.item[key] }}
                      <b-button
                        v-if="key === '_certificate' && !hideDownloadCertificateBtn"
                        @click.prevent="downloadCertificate(row.item._id)"
                        variant="primary"
                      >
                        Télécharger l'attestation
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Masquer les détails
                  </b-button>
                </b-card>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BBadge,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";

import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    StatisticCardVertical,
    VueApexCharts,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
  },
  data() {
    return {
      totalLogsNb: 0,
      peersConnected: 0,
      outOfSyncInterval: 0,
      logSearchInput: "",
      realtimeLogsUpdate: true,
      lastBlockId: null,
      // Champs masqués dans les détails d'un log
      hideFields: ['status', '_showDetails', 'id', '_owner_or_delegate'],

      apexChartData: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: "straight",
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: 1,
            type: "solid",
          },
          tooltip: {
            shared: false,
          },
          colors: ["#2bdac7"],
        },
      },
      // rangePicker: ["2019-05-01", "2019-05-10"],

      nodeState: [
        {
          title: "Version",
          avatar: "HexagonIcon",
          avatarVariant: "light-success",
          value: "0.2",
        },
        {
          title: "Connexion au réseau",
          avatar: "WifiIcon",
          avatarVariant: "light-danger",
          value: "Chargement...",
        },
        {
          title: "Dernière Synchronisation",
          avatar: "ClockIcon",
          avatarVariant: "light-danger",
          value: "Chargement...",
        },
      ],

      fields: [
        // A column that needs custom formatting
        { key: "_id", label: "# Block" },
        { key: "_timestamp", label: "Horodatage" },
        { key: "_signature", label: "Signature" },
        { key: "status", label: "Statut" },
        { key: "_owner_or_delegate", label: "Type signature" },
        { key: "details", label: "Détails" },
      ],
      logs: [],
    };
  },
  watch: {
    realtimeLogsUpdate: function () {
      if (this.realtimeLogsUpdate) {
        this.logs = [];
        this.lastBlockId = null;
        this.logSearchInput = "";
        this.updateLastLogs();
      }
    },
  },
  mounted() {
    if (this.$route.params.log) {
      const id = Number(this.$route.params.log);
      this.logSearchInput = id;
      this.realtimeLogsUpdate = false;
      this.fetchLogById(id);
    }

    // Auto-refresh
    setInterval(() => {
      this.updateTotalLogsNb();
      this.updatePeers();
      if (this.realtimeLogsUpdate) this.updateLastLogs();
      // this.updateLogsStats();
    }, 15000);

    this.updateTotalLogsNb();
    this.updatePeers();
    if (this.realtimeLogsUpdate) this.updateLastLogs();
    this.updateLogsStats();
  },
  computed: {
    hideDownloadCertificateBtn() {
      return (window.location.href.indexOf("openlogs.fr") > -1);
    }
  },
  methods: {
    downloadCertificate(id) {
      axios.get(`/api/logs/${id}/certificate`).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+data.data;
        a.download = `attestation-openlogs-${id}.pdf`;
        a.click();
      });
    },
    fetchLogById(id) {
      this.realtimeLogsUpdate = false;

      axios
        .get(`/api/logs?id=${id}&signature=1`)
        .then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            for (const log of res.data.data) {
              log._showDetails = true;
              log._timestamp = new Date(log._timestamp).toLocaleString();
              
              log._owner_or_delegate = log._owner == log._author
                ? { status: "Propriétaire", variant: "success" }
                : { status: "Déléguée", variant: "info" };
              
              log.status = log._signature
                ? { status: "Vérifié", variant: "success" }
                : { status: "N/C", variant: "danger" };
            }
            this.logs = res.data.data; // logs
          } else {
            alert(`Aucun résultat pour #${this.logSearchInput}`);
          }
        })
        .catch((err) => {
          console.error(err);
          this.realtimeLogsUpdate = true;
        });
    },
    logSearch(e) {
      e.preventDefault();

      this.logSearchInput = Number(this.logSearchInput);
      this.fetchLogById(this.logSearchInput);
    },
    updateTotalLogsNb() {
      axios
        .get(`/api/logs-total-nb`)
        .then((res) => {
          this.totalLogsNb = res.data.data;
          this.setLastSyncNow();
        })
        .catch((err) => {
          this.setOutOfSync();
        });
    },
    updatePeers() {
      axios
        .get(`/api/peers`)
        .then(({ data }) => {
          this.peersConnected = `${data.data.length} / 273`;
          this.setLastSyncNow();
        })
        .catch((err) => {
          console.error(err);
          this.setOutOfSync();
        });
    },
    updateLastLogs() {
      axios
        .get('/api/logs', {
          params: {
            limit: this.lastBlockId !== null ? -1 : 30,
            signature: 1,
            order: 'desc',
            afterBlock: this.lastBlockId !== null ? this.lastBlockId : null,
          }
        })
        .then((res) => {
          res.data.data.reverse();
          for (const log of res.data.data) {
            log.status = { status: "Vérifié", variant: "success" };
            log._timestamp = moment(log._timestamp).format("YYYY-MM-DD HH:mm:ss.SSS");
            log._owner_or_delegate = log._owner == log._author
              ? { status: "Propriétaire", variant: "success" }
              : { status: "Déléguée", variant: "info" };
            this.logs.unshift(log);
          }
          this.lastBlockId = this.logs[0]._id ?? null;
          this.setLastSyncNow();
        })
        .catch((err) => {
          this.setOutOfSync();
        });
    },
    updateLogsStats() {
      const to = new Date();
      const from = new Date();
      from.setDate(from.getDate() - 30);
      
      axios
        .get(`/api/logs-stats?from=${from.toISOString().substring(0, 10)}&to=${to.toISOString().substring(0, 10)}`)
        .then((res) => {
          this.$refs.logsChart.updateOptions({
            series: [
              {
                name: "Logs ancrés",
                data: Object.values(res.data.data),
              },
            ],
            chartOptions: {
              xaxis: {
                categories: [Object.keys(res.data.data)],
              },
            },
          });
          this.apexChartData.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: Object.keys(res.data.data).map((d) =>
                  moment(d).format("DD/MM")
                ),
              },
            },
          };
          this.setLastSyncNow();
        })
        .catch((err) => {
          this.setOutOfSync();
        });
    },
    setLastSyncNow() {
      this.outOfSyncInterval = 0;
      this.nodeState[1].avatarVariant = "light-success";
      this.nodeState[1].value = "Connecté";

      this.nodeState[2].avatarVariant = "light-success";
      this.nodeState[2].value = moment().format("YYYY-MM-DD HH:mm:ss")
        // new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
    },
    setOutOfSync() {
      this.outOfSyncInterval++;

      if (this.outOfSyncInterval > 8) {
        this.nodeState[2].avatarVariant = "light-warning";
        this.nodeState[1].avatarVariant = "light-warning";
        this.nodeState[1].value = "Déconnecté";
      } else {
        this.nodeState[2].avatarVariant = "light-danger";
        this.nodeState[1].avatarVariant = "light-danger";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
